export const VAAKSY_JYVASKYLA = {
  name: "Vääksy-Jyväskylä",
  routes: [
    {
      weight_name: "routability",
      legs: [
        {
          summary: "314, 612",
          steps: [],
          distance: 90824.016,
          duration: 5848.731,
          weight: 5848.731,
        },
        {
          summary: "Luhangantie, 610",
          steps: [],
          distance: 36057.672,
          duration: 2032.238,
          weight: 2032.238,
        },
      ],
      geometry: {
        coordinates: [
          [25.547323, 61.172077],
          [25.54707, 61.172108],
          [25.52216, 61.179733],
          [25.487293, 61.195911],
          [25.490742, 61.238491],
          [25.556307, 61.321178],
          [25.7237, 61.445305],
          [25.719645, 61.484848],
          [25.684998, 61.502335],
          [25.647797, 61.559246],
          [25.664249, 61.618587],
          [25.609594, 61.66349],
          [25.657078, 61.686333],
          [25.544333, 61.726734],
          [25.689619, 61.803993],
          [25.703335, 61.796803],
          [25.703335, 61.796803],
          [25.703859, 61.797054],
          [25.702694, 61.797287],
          [25.68964, 61.804131],
          [25.685249, 61.823807],
          [25.781368, 61.839684],
          [25.717026, 61.893208],
          [25.729204, 61.930489],
          [25.69203, 61.952305],
          [25.679455, 62.003674],
          [25.603567, 62.031868],
        ],
        type: "LineString",
      },
      distance: 126881.68,
      duration: 7880.969,
      weight: 7880.969,
    },
  ],
  waypoints: [
    {
      distance: 5.266,
      name: "Anianpellontie",
      location: [25.547323, 61.172077],
    },
    {
      distance: 3.339,
      name: "Keskustie",
      location: [25.703335, 61.796803],
    },
    {
      distance: 3.672,
      name: "Vespuolentie",
      location: [25.603567, 62.031868],
    },
  ],
  code: "Ok",
  uuid: "sSi0v-1fLsmzkDenz2AHkC3fX-625b7w-xyLJ6v3hI6wsW7gdL8lzA==",
}
