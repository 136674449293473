export const KARIGASNIEMI_UTSJOKI = {
  name: "Karigasniemi-Utsjoki",
  routes: [
    {
      weight_name: "routability",
      legs: [
        {
          summary: "970",
          steps: [],
          distance: 26853.764,
          duration: 1925.13,
          weight: 1925.13,
        },
        {
          summary: "970",
          steps: [],
          distance: 73829.391,
          duration: 3698.571,
          weight: 3698.571,
        },
      ],
      geometry: {
        coordinates: [
          [25.856413, 69.397728],
          [25.823997, 69.418114],
          [25.820442, 69.433395],
          [25.834867, 69.460747],
          [25.867441, 69.474556],
          [25.877472, 69.549057],
          [25.950848, 69.576591],
          [25.983185, 69.612518],
          [25.953087, 69.650299],
          [25.91164, 69.662811],
          [25.968903, 69.702415],
          [26.149036, 69.740189],
          [26.247414, 69.793762],
          [26.253323, 69.815536],
          [26.341373, 69.836761],
          [26.417578, 69.871078],
          [26.416584, 69.898155],
          [26.458311, 69.919601],
          [26.464338, 69.935539],
          [26.648081, 69.959976],
          [26.719004, 69.94342],
          [26.834846, 69.957245],
          [26.869078, 69.933235],
          [26.948395, 69.936348],
          [27.027544, 69.91008],
        ],
        type: "LineString",
      },
      distance: 100683.156,
      duration: 5623.7,
      weight: 5623.7,
    },
  ],
  waypoints: [
    {
      distance: 1.719,
      name: "Badjedeanugeaidnu",
      location: [25.856413, 69.397728],
    },
    {
      distance: 3.461,
      name: "Tenontie",
      location: [25.983185, 69.612518],
    },
    {
      distance: 7.205,
      name: "970",
      location: [27.027544, 69.91008],
    },
  ],
  code: "Ok",
  uuid: "bjhn4cE0Gq95O-6kg7RmTw1wHhGTzbgDKy_qy8QoCoc-sua12bW6ag==",
}
