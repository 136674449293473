export const KRISTINA_PUUMALA = {
  name: "Ristiina-Puumala",
  routes: [
    {
      weight_name: "routability",
      legs: [
        {
          summary: "4323",
          steps: [],
          distance: 17544.273,
          duration: 1566.593,
          weight: 1566.593,
        },
        {
          summary: "4323, 62",
          steps: [],
          distance: 59518.207,
          duration: 5134.539,
          weight: 5134.539,
        },
      ],
      geometry: {
        coordinates: [
          [27.257986, 61.506813],
          [27.322969, 61.521599],
          [27.362984, 61.513821],
          [27.390738, 61.490776],
          [27.413311, 61.483337],
          [27.449202, 61.47673],
          [27.494232, 61.48111],
          [27.51951, 61.469635],
          [27.542547, 61.459549],
          [27.562202, 61.462215],
          [27.609324, 61.454689],
          [27.662136, 61.458645],
          [27.667473, 61.449604],
          [27.70896, 61.439125],
          [27.714037, 61.429558],
          [27.775063, 61.420036],
          [27.784889, 61.42141],
          [27.784246, 61.429089],
          [27.797207, 61.430893],
          [27.811752, 61.441811],
          [27.847569, 61.488079],
          [27.829414, 61.498672],
          [27.860794, 61.501034],
          [27.891193, 61.519024],
          [27.907398, 61.515865],
          [27.915359, 61.522408],
          [27.946192, 61.527473],
          [27.96257, 61.553791],
          [27.97699, 61.56144],
          [28.032444, 61.560043],
          [28.044296, 61.566895],
          [28.069515, 61.568974],
          [28.110048, 61.562614],
          [28.163897, 61.578175],
          [28.181068, 61.57436],
          [28.184851, 61.568962],
          [28.184336, 61.529133],
          [28.173298, 61.516071],
        ],
        type: "LineString",
      },
      distance: 77062.477,
      duration: 6701.132,
      weight: 6701.132,
    },
  ],
  waypoints: [
    {
      distance: 3.058,
      name: "Saimaantie",
      location: [27.257986, 61.506813],
    },
    {
      distance: 1.7,
      name: "Suurlahdentie",
      location: [27.51951, 61.469635],
    },
    {
      distance: 4.138,
      name: "Imatrantie",
      location: [28.173298, 61.516071],
    },
  ],
  code: "Ok",
  uuid: "FvNw3yblFSHveTf2IpLeowz1TCthJWGMi4hBNG3XvHT1IFIbhlsY4g==",
}
