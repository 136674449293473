export const MUROLEEN_KANAVA_KAPEE_TERALAHTI = {
  name: "Muroleen kanava-Terälahti",
  routes: [
    {
      weight_name: "routability",
      legs: [
        {
          summary: "Muroleen kanavantie, Pohjankapeentie",
          steps: [],
          distance: 12117.696,
          duration: 1827.214,
          weight: 1827.214,
        },
        {
          summary: "Kapeentie, Löytänäntie",
          steps: [],
          distance: 15667.576,
          duration: 2780.663,
          weight: 2780.663,
        },
      ],
      geometry: {
        coordinates: [
          [23.906801, 61.855362],
          [23.905764, 61.856346],
          [23.905033, 61.855961],
          [23.905188, 61.855175],
          [23.92177, 61.846207],
          [23.925989, 61.834183],
          [23.950891, 61.821495],
          [23.947739, 61.818954],
          [23.899738, 61.808456],
          [23.880173, 61.815281],
          [23.837282, 61.811501],
          [23.837181, 61.811516],
          [23.840708, 61.795719],
          [23.8545, 61.792217],
          [23.852736, 61.784416],
          [23.846008, 61.785328],
          [23.841829, 61.781757],
          [23.835253, 61.785797],
          [23.827429, 61.783733],
          [23.825727, 61.776455],
          [23.835794, 61.764595],
          [23.835381, 61.755775],
          [23.838053, 61.746044],
          [23.84753, 61.742661],
          [23.855051, 61.732391],
          [23.897554, 61.70871],
        ],
        type: "LineString",
      },
      distance: 27785.275,
      duration: 4607.877,
      weight: 4607.877,
    },
  ],
  waypoints: [
    {
      distance: 1.104,
      name: "",
      location: [23.906801, 61.855362],
    },
    {
      distance: 0.871,
      name: "Pohjankapeentie",
      location: [23.837282, 61.811501],
    },
    {
      distance: 0.806,
      name: "Kapeentie",
      location: [23.897554, 61.70871],
    },
  ],
  code: "Ok",
  uuid: "nnbRZIMko6_UnkzNqPhRCL6QdDnaAqVBNPatxMyiHOqN5Uy4jH2FjQ==",
}
