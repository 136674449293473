export const MERIKARVIA_BJORNEBORG = {
  name: "Merikarvia-Pori",
  routes: [
    {
      weight_name: "routability",
      legs: [
        {
          summary: "2680, Ahlaistentie",
          steps: [],
          distance: 27720.42,
          duration: 2410.755,
          weight: 2410.755,
        },
        {
          summary: "Pohjoinen satamatie, Reposaaren maantie",
          steps: [],
          distance: 17605.934,
          duration: 1528.609,
          weight: 1528.609,
        },
        {
          summary: "Kallontie, 2",
          steps: [],
          distance: 23334.023,
          duration: 2001.326,
          weight: 2001.326,
        },
      ],
      geometry: {
        coordinates: [
          [21.500835, 61.85812],
          [21.56781, 61.812271],
          [21.565825, 61.806301],
          [21.537689, 61.80275],
          [21.546873, 61.795532],
          [21.544153, 61.781044],
          [21.527489, 61.768299],
          [21.557821, 61.756741],
          [21.57431, 61.738873],
          [21.638023, 61.703247],
          [21.631044, 61.693314],
          [21.641245, 61.671322],
          [21.626945, 61.6731],
          [21.626858, 61.672974],
          [21.622446, 61.659134],
          [21.614931, 61.643635],
          [21.556993, 61.632004],
          [21.510353, 61.630138],
          [21.4977, 61.622128],
          [21.524019, 61.591797],
          [21.508532, 61.589695],
          [21.482468, 61.59198],
          [21.465113, 61.590923],
          [21.465248, 61.590664],
          [21.465729, 61.590569],
          [21.466185, 61.59079],
          [21.482468, 61.59198],
          [21.520317, 61.586384],
          [21.587887, 61.550461],
          [21.745831, 61.490742],
          [21.759327, 61.479336],
          [21.776031, 61.478344],
          [21.779785, 61.477108],
          [21.78014, 61.478725],
          [21.784918, 61.480473],
          [21.785666, 61.48074],
          [21.788015, 61.481899],
          [21.78846, 61.48259],
          [21.793596, 61.482109],
          [21.795156, 61.485344],
        ],
        type: "LineString",
      },
      distance: 68660.375,
      duration: 5940.689,
      weight: 5940.689,
    },
  ],
  waypoints: [
    {
      distance: 7.358,
      name: "Eteläinen rantatie",
      location: [21.500835, 61.85812],
    },
    {
      distance: 7.689,
      name: "Ämtööntie",
      location: [21.626858, 61.672974],
    },
    {
      distance: 1.565,
      name: "",
      location: [21.465248, 61.590664],
    },
    {
      distance: 3.769,
      name: "Antinkatu",
      location: [21.795156, 61.485344],
    },
  ],
  code: "Ok",
  uuid: "JRLH8ZkjFnOZ2x_qqojlOBXlgU3Y-YncY8gB-FPzkYYheezrFSUCHQ==",
}
