export const MUHOS_HAILUOTO = {
  name: "Muhos-Hailuoto",
  routes: [
    {
      weight_name: "routability",
      legs: [
        {
          summary: "22, 816",
          steps: [],
          distance: 88311.414,
          duration: 7275.958,
          weight: 7275.958,
        },
      ],
      geometry: {
        coordinates: [
          [25.99444, 64.807442],
          [25.988974, 64.808807],
          [25.988523, 64.808899],
          [25.918148, 64.831779],
          [25.88735, 64.862419],
          [25.74507, 64.928238],
          [25.561405, 64.980759],
          [25.546, 64.963402],
          [25.505014, 64.955917],
          [25.430876, 64.939064],
          [25.430611, 64.939117],
          [25.348888, 64.971092],
          [25.287582, 64.975288],
          [25.251913, 64.995392],
          [25.195974, 65.008957],
          [25.068167, 65.037476],
          [24.967373, 65.035339],
          [24.87462, 65.061195],
          [24.823948, 65.060173],
          [24.778419, 65.04789],
          [24.771414, 65.028008],
          [24.698357, 65.007584],
          [24.563393, 65.040344],
          [24.563421, 65.040291],
        ],
        type: "LineString",
      },
      distance: 88311.414,
      duration: 7275.958,
      weight: 7275.958,
    },
  ],
  waypoints: [
    {
      distance: 1.924,
      name: "22",
      location: [25.99444, 64.807442],
    },
    {
      distance: 3.683,
      name: "",
      location: [24.563421, 65.040291],
    },
  ],
  code: "Ok",
  uuid: "QOJS4YKI8pw0ToPUS0_aHiJ1kDGEPIK_eDdRMdukwfX2m-mHJTQfWw==",
}
